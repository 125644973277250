import React from 'react';

import { GetServerSidePropsResult } from 'next';

import { Vertical } from '../../types/common';
import RealEstateHome from '@/components/home/_verticals/realestate/RealestateHome';
import { InitVerticalHomeContextProps } from '@/components/home/_verticals/types/home';
import { RealestateHomeProps } from '@/components/home/_verticals/types/realestate';
import { cacheLifetime } from '@/config';
import VerticalHomeContextWrapper from '@/contexts/VerticalHome/VerticalHomeContextWrapper';
import getRealEstatePageServerProps from '@/data/pages/ingatlan/serverState';

const RealEstateHomePage = (props: RealestateHomeProps): React.ReactNode => {
  const { heroData, shopData } = props;

  const verticalHome: InitVerticalHomeContextProps = {
    vertical: Vertical.Realestate,
    heroData,
    shopData,
  };

  return (
    <VerticalHomeContextWrapper verticalHome={verticalHome}>
      <RealEstateHome {...props} />
    </VerticalHomeContextWrapper>
  );
};

export async function getServerSideProps(
  { res }: { res: any },
): Promise<GetServerSidePropsResult<RealestateHomeProps>> {
  res.setHeader(
    'Cache-Control',
    cacheLifetime,
  );

  const props = await getRealEstatePageServerProps();

  return { props };
}

export default RealEstateHomePage;
